import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import get from 'lodash/get';
import Layout from '../components/layout';
import BackgroundImage from 'gatsby-background-image';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import styles from './event.module.css';

class EventTemplate extends React.Component {
  render() {
    const event = get(this.props, 'data.contentfulVeranstaltung');
    const { heroImage } = get(this, 'props.data.contentfulSeite');
    const sponsors = get(this, 'props.data.allContentfulSponsor.edges');
    let images;
    
    if (event.images) {
      images = event.images.map(({ file, internal }) => ({
        original: file.url,
        thumbnail: file.url,
        description: internal.description
      }));
    }

    const fullTimeSring = `, ${event.startTime}${event.startDate === event.endDate ? ` - ${event.endTime}` : ` bis ${event.endDate}, ${event.endTime}`}`

    return (
      <Layout location={this.props.location} sponsors={sponsors}>
        <div style={{ background: '#fff' }}>
          <SEO title={event.name} article />
          <BackgroundImage backgroundColor="#0e3920" className="header" fluid={heroImage.fluid}>
            <h1>Veranstaltungen</h1>
          </BackgroundImage>
          <div className="container">
            <h2 className={styles.title}>{event.name}</h2>
            <span className={styles.dateInfo}>Datum</span>
            <h3 className={styles.date}>
              {event.startDate}
              {(event.startTime !== event.endTime) ? fullTimeSring : (event.startTime === '00:00' ? '' : `, ${event.startTime}`)}
            </h3>
            {event.location &&
              <React.Fragment>
                <span className={styles.dateInfo}>Ort</span>
                <h3 className={styles.location}>{event.location}</h3>
              </React.Fragment>
            }
            <br></br>
            {event.description &&
              <article className="markdown" dangerouslySetInnerHTML={{
                __html: event.description.childMarkdownRemark.html,
              }} />
            }
            {images && 
              <React.Fragment>
                <h3>Bildergalerie</h3>
                <ImageGallery 
                  items={images} 
                  showPlayButton={false}
                />
              </React.Fragment>
            }
          </div>
        </div>
      </Layout>
    )
  }
}

export default EventTemplate;

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    contentfulSeite(slug: { eq: "veranstaltungen" }) {
      heroImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
    contentfulVeranstaltung(slug: { eq: $slug }) {
      name
      startTime: startDate(formatString: "HH:mm")
      startDate(formatString: "DD.MM.YYYY")
      endTime: endDate(formatString: "HH:mm")
      endDate(formatString: "DD.MM.YYYY")
      location
      description {
        childMarkdownRemark {
          html
        }
      }
      images {
        file {
          url
        }
        internal {
          description
        }
      }
    }
    allContentfulSponsor(filter: { id: { ne: "10296087-115a-526e-9f65-04404f89693a" }}) {
      edges {
        node {
          name
          website
          logo {
            fixed(width: 250, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
